import React from "react";
import Layout from "../components/layout";
import Typewriter from "../components/typewriter";
import styled from "styled-components";
import Helmet from "react-helmet";
import Img from "gatsby-image/withIEPolyfill";
import { graphql } from "gatsby";

export default ({ data }) => {
    const [image] = data.Image.edges;
    const {
        childImageSharp: { fluid },
    } = image.node;

    return (
        <>
            <Helmet>
                <html lang="en"/>
                <meta charSet="utf-8"/>
                <title>My Portfolio - Jordan Miller</title>
                <meta name="description" content="I'm Jordan Miller; a full stack web developer, and this is my portfolio site!" />
                <link rel="canonical" href="https://jordanmiller.dev"/>
                <meta property="og:image:secure_url" content="https://jordanmiller.dev/ogpImage.jpg"/>
                <meta property="og:image" content="http://jordanmiller.dev/ogpImage.jpg" />
                <meta property="og:title" content="My Portfolio - Jordan Miller" />
                <meta property="og:description" content="I'm Jordan Miller; a full stack web developer, and this is my portfolio site!"/>
                <meta property="og:url" content="https://jordanmiller.dev" />
            </Helmet>
            <Layout>
                <Typewriter text="Hello :)" />
                <P>I'm an Army veteran and full-stack software engineer. I love solving problems with code.</P>
                <P>I got into programming to make mods for video games I was playing, and went to Lambda School to learn Web development in 2019. My goal was to gain professional skills 
                    to build on my self taught programming knowledge and transition into a tech career. I've since started a company (<a href="https://quackhire.com" target="_blank" rel="noopener noreferrer">Quackhire</a>), 
                    and am seeking fulltime or contract/freelance work to help fund it. I'm very comfortable in the MERN stack and will bring a lot of value to your team.</P>
                <P>I'm ambitious and work extremely hard. I averaged over ten contributions per day in my first year on Github, and am currently on a 200+ day streak of programming every day.</P>
                <a href="https://github.com/jordanjmiller" target="_blank" rel="noopener noreferrer">
                    <ImgWrapper>
                        <StyledImg title={"Jordan's Github graph Oct 17th 2020. Click to view Jordan's Github"} alt="Jordan's Github graph Oct 17th 2020" fluid={fluid}/>
                    </ImgWrapper>
                </a>
                <P>Feel free to <a href="mailto:miller.jordan1715@gmail.com" title="Jordan's Email">email</a> or connect on <a href="https://linkedin.com/in/jordanmi-dev" target="_blank" rel="noopener noreferrer" title="Jordan's LinkedIn">LinkedIn</a> if you'd like to chat!</P>
            </Layout>
        </>
    );
};

export const query = graphql`
    query image {
        Image: allFile(filter: { relativePath: { regex: "/githubgraph3/" } }) {
            edges {
                node {
                    relativePath
                    name
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 100, srcSetBreakpoints: [800, 700, 600, 500, 400, 300, 200]) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

const P = styled.p`
    color: ${(props) => props.theme.primary};
`;

const StyledImg = styled(Img)`
    grid-row: 1 / -1;
    margin: 0;
    padding: 0 0.3rem;
    border-radius: 5px;
    filter: brightness(0.95);
`;

const ImgWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 50px;
`;
